<template>
	<div class="card">
		<div class="card-image">
			<figure class="image is-4by3">
				<img :src="'https://kit-back.bro.digital/storage/'+movie.poster" :alt="movie.title">
			</figure>
		</div>
		<div class="card-content">
			<div style="text-align: left">
				<h2>
					{{ movie.title }}
				</h2>
				<div v-html="movie.description"></div>
			</div>
			<div class="content">
				<rating :items="items" legend="Поставьте оценку этой фильму" kind="checkmark" :value="value" @change="update"></rating>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import Rating from 'vue-bulma-rating'

export default{
	props: {
		movie: {
			required: true
		},
		ticket: {
			required: true
		}
	},
	components: {
		Rating
	},
	data(){
		return {
			value: 0,
			items: [
				{ title: '10 баллов', value: 10 },
				{ title: '9 баллов', value: 9 },
				{ title: '8 баллов', value: 8 },
				{ title: '7 баллов', value: 7 },
				{ title: '6 баллов', value: 6 },
				{ title: '5 баллов', value: 5 },
				{ title: '4 балла', value: 4 },
				{ title: '3 балла', value: 3 },
				{ title: '2 балла', value: 2 },
				{ title: '1 балл', value: 1 }
			]
		}
	},
	methods: {
		async update (val) {
			if(typeof val == 'number'){
				this.value = val
				await axios.get(`https://kit-back.bro.digital/api/tickets/${this.ticket}/${this.movie.id}/vote/${val}`)
			}
		}
	}
}
</script>