<template>
	<section class="hero is-info is-fullheight">

		<div class="hero-body">
			<div class="container has-text-centered">

				<transition name="slide-fade">
					<div v-if="stage == 'pincode'">
						<h1 class="title">Введите пинкод</h1>

						<div class="field" style="max-width: 300px; margin: 0 auto;">
							<div class="control is-large" :class="[{ 'is-loading': stage == 'check_pincode' }]">
								<input class="input is-large" type="text" v-model="pincode" placeholder="Введите пинкод">
							</div>
						</div>

					</div>
				</transition>
				<transition name="slide-fade">
					<div v-if="stage == 'vote'" style="max-width: 500px; margin: 0 auto;">
						<h1 class="title">Голосование</h1>

						<movie v-for="movie, index in movies" :ticket="ticket" :movie="movie" :key="index" />

					</div>
				</transition>
				<transition name="slide-fade">
					<div v-if="stage == 'bluff'">
						<h1>Неудачненько</h1>
						{{ ticket }}
						{{ bluff_message }}
					</div>
				</transition>
			</div>
		</div>

	</section>
</template>

<script>
import axios from 'axios'
import Movie from './Movie'

export default{
	props: {
		ticket: {
			required: true
		}
	},
	components: {
		Movie,
	},
	data(){
		return {
			stage: 'prepare',
			pincode: null,
			bluff_message: null,
			movies: null
		}
	},
	watch: {
		pincode: function(val){
			if(val.length == 6){
				this.stage = 'check_pincode'
				this.check_pincode()
			}
		}
	},
	mounted(){
		this.prepare()
	},
	methods: {
		async prepare(){
			await axios.get(`https://kit-back.bro.digital/api/tickets/${this.ticket}`).then(r => {
				if(r.data.result == 'ok' && r.data.proceed_to == 'pincode'){
					this.stage = 'pincode'
				} else {
					this.stage = 'bluff'
					this.bluff_message = r.data.message
				}
			})
		},
		async check_pincode(){
			await axios.get(`https://kit-back.bro.digital/api/tickets/${this.ticket}/${this.pincode}`).then(r => {
				if(r.data.result == 'ok' && r.data.proceed_to == 'vote'){
					this.stage = 'vote'
					this.movies = r.data.movies
				} else {
					this.stage = 'bluff'
					this.bluff_message = r.data.message
				}
			})
		}
	}
}
</script>
<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.starability-checkmark.rating{
	width: 300px !important;
	margin: 0 auto !important;
}
</style>